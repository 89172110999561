import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #d9c8a3;
  color: #333;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 20px;
  text-align: center;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`;

const StyledButton = styled.button`
  background: #c2a679;
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background: #a68a59;
  }
`;

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accettato");
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "rifiutato");
    setVisible(false);
  };

  if (!visible) return null;

  return (
      <StyledContainer>
        <StyledText>
          Unisciti alla nostra comunità di sviluppatori! Collaboriamo insieme a progetti entusiasmanti.
        </StyledText>
        <StyledButtons>
          <StyledButton onClick={handleAccept}>Unisciti</StyledButton>
          <StyledButton onClick={handleDecline}>Annulla</StyledButton>
        </StyledButtons>
      </StyledContainer>
  );
};

export default CookieBanner;