import React from "react";
import styled from "styled-components";

const UniqueContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: #f5f5dc;
    box-sizing: border-box;
`;

const UniqueTitle = styled.h2`
    text-align: center;
    font-size: 30px;
    margin-bottom: 50px;
    color: #8b4513;
    font-weight: bold;
`;

const UniqueGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`;

const UniqueCard = styled.div`
    background-color: #fff8dc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    flex: 1;
    min-width: 240px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
`;

const UniqueImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const UniqueContent = styled.div`
    padding: 15px;
`;

const UniqueCardTitle = styled.h3`
    font-size: 20px;
    margin-bottom: 10px;
    color: #8b4513;
`;

const UniqueCardDescription = styled.p`
    font-size: 14px;
    color: #5b5b5b;
    line-height: 1.3;
`;

const portfolioData = [
  {
    image: "port1.jpg",
    title: "Unisciti a Noi",
    description:
      "Siamo un gruppo di sviluppatori appassionati pronti a collaborare su progetti innovativi e stimolanti."
  },
  {
    image: "port2.jpg",
    title: "Collaborazione Creativa",
    description:
      "Crediamo nel potere della collaborazione per creare soluzioni digitali uniche e di valore."
  },
  {
    image: "port3.jpg",
    title: "Progetti Insieme",
    description:
      "Lavoriamo insieme per realizzare progetti che riflettano le nostre competenze e la nostra passione."
  },
  {
    image: "port4.jpg",
    title: "Crescita della Comunità",
    description:
      "Unisciti alla nostra comunità di sviluppatori e condividi idee, esperienze e progetti."
  }
];

const UniqueComponent = () => {
  return (
    <UniqueContainer>
      <UniqueTitle>Le Nostre Iniziative</UniqueTitle>
      <UniqueGrid>
        {portfolioData.map((item, index) => (
          <UniqueCard key={index}>
            <UniqueImage src={item.image} alt={item.title} />
            <UniqueContent>
              <UniqueCardTitle>{item.title}</UniqueCardTitle>
              <UniqueCardDescription>{item.description}</UniqueCardDescription>
            </UniqueContent>
          </UniqueCard>
        ))}
      </UniqueGrid>
    </UniqueContainer>
  );
};

export default UniqueComponent;