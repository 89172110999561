import styled from "styled-components";

const StyledContainer = styled.section`
    width: 100%;
    padding: 40px 10px;
    background: linear-gradient(135deg, #e7d7b1, #f2e5d5);
    box-sizing: border-box;
`;

const StyledBlock = styled.div`
    max-width: 700px;
    margin: 30px auto;
    padding: 30px 20px;
    background-color: #d1cfcf;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
`;

const StyledHeading = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #8b5a3c;
    font-weight: bold;
`;

const StyledText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: #3c2a1f;

    p {
        margin-bottom: 15px;
    }

    a {
        color: #8b5a3c;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <StyledContainer>
      <StyledBlock>
        <StyledHeading>Unisciti alla Community di BlockSpira!</StyledHeading>
        <StyledText>
          <p>
            In BlockSpira, valorizziamo la collaborazione e l'innovazione. Invitiamo tutti i membri della community di sviluppatori a partecipare attivamente nella creazione di progetti entusiasmanti insieme.
          </p>
          <p>
            La nostra piattaforma offre un ambiente stimolante per condividere idee, apprendere e crescere insieme. Unendoti a noi, avrai l'opportunità di connetterti con altri sviluppatori appassionati e contribuire a progetti significativi.
          </p>
          <p>
            Per ulteriori dettagli e per unirti a noi, visita il nostro{" "}
            <a href="/about" target="_blank" rel="noopener noreferrer">
              Spazio di Collaborazione
            </a>.
          </p>
        </StyledText>
      </StyledBlock>
    </StyledContainer>
  );
};
export default CookiePolicy;