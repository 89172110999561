import React from "react";
import styled from "styled-components";

const PrivacyWrapper = styled.section`
    background: #f2e7d5;
    color: #4a3c3c;
    padding: 60px 30px;
`;

const PrivacyContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
    font-size: 28px;
    font-weight: bold;
    color: #7c5f4f;
    margin-bottom: 25px;
    text-align: center;
`;

const PrivacyText = styled.p`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 25px;
`;

const PrivacyList = styled.ul`
    margin: 25px 0;
    padding-left: 25px;
`;

const PrivacyListItem = styled.li`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 12px;
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyWrapper>
            <PrivacyContainer>
                <PrivacyTitle>Politica di Comunità</PrivacyTitle>
                <PrivacyText>
                    In **BlockSpira**, crediamo nella forza della comunità di sviluppatori. Questa politica descrive come ci impegniamo a creare un ambiente collaborativo e innovativo per tutti.
                </PrivacyText>

                <PrivacyTitle>1. Unisciti a Noi</PrivacyTitle>
                <PrivacyText>
                    Ti invitiamo a partecipare ai nostri progetti, dove potrai:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Collaborare con altri sviluppatori</PrivacyListItem>
                    <PrivacyListItem>Condividere idee e risorse</PrivacyListItem>
                    <PrivacyListItem>Imparare e crescere insieme</PrivacyListItem>
                    <PrivacyListItem>Contribuire a progetti open source</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>2. Come Partecipare</PrivacyTitle>
                <PrivacyText>
                    Per unirti a noi, puoi:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Registrarti sulla nostra piattaforma</PrivacyListItem>
                    <PrivacyListItem>Partecipare ai nostri eventi e hackathon</PrivacyListItem>
                    <PrivacyListItem>Seguire i nostri canali social per aggiornamenti</PrivacyListItem>
                    <PrivacyListItem>Interagire con altri membri della comunità</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>3. Rispetto e Collaborazione</PrivacyTitle>
                <PrivacyText>
                    Ci impegniamo a mantenere un ambiente rispettoso e inclusivo. È importante:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Trattare tutti con rispetto</PrivacyListItem>
                    <PrivacyListItem>Essere disponibili ad aiutare gli altri</PrivacyListItem>
                    <PrivacyListItem>Accogliere la diversità delle idee</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>4. Sicurezza della Comunità</PrivacyTitle>
                <PrivacyText>
                    La sicurezza dei membri della comunità è fondamentale. Adottiamo misure per proteggere:
                </PrivacyText>

                <PrivacyTitle>5. I Tuoi Diritti</PrivacyTitle>
                <PrivacyText>
                    Hai il diritto di:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Accedere alle informazioni che condividi</PrivacyListItem>
                    <PrivacyListItem>Richiedere modifiche o cancellazioni</PrivacyListItem>
                    <PrivacyListItem>Contribuire attivamente alla comunità</PrivacyListItem>
                    <PrivacyListItem>Segnalare comportamenti inappropriati</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>6. Cookies</PrivacyTitle>
                <PrivacyText>
                    Utilizziamo i cookie per migliorare la tua esperienza. Puoi gestire le tue preferenze in qualsiasi momento.
                </PrivacyText>

                <PrivacyTitle>7. Modifiche alla Politica</PrivacyTitle>
                <PrivacyText>
                    Ci riserviamo il diritto di aggiornare questa politica. Le modifiche saranno pubblicate su questa pagina.
                </PrivacyText>

                <PrivacyTitle>8. Contatti</PrivacyTitle>
                <PrivacyText>
                    Per domande sulla comunità, puoi contattarci a:
                </PrivacyText>
                <PrivacyText>
                    📧 Email: contact@blockspira.it
                </PrivacyText>
                <PrivacyText>
                    📍 Indirizzo: Via della Collaborazione, 123, 00100 Roma, Italia
                </PrivacyText>
            </PrivacyContainer>
        </PrivacyWrapper>
    );
};

export default PrivacyPolicy;