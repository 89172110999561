import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.section`
    width: 100%;
    padding: 50px 15px;
    background: linear-gradient(270deg, #d8c4a1, #e2d1b9);
    box-sizing: border-box;
    text-align: center;
    color: #4b3c30;
`;

const StyledTitle = styled.h2`
    font-size: 32px;
    margin-bottom: 20px;
`;

const StyledText = styled.p`
    font-size: 18px;
    margin-bottom: 30px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledInput = styled.input`
    padding: 12px 18px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 280px;
    background-color: #f5f5f5;
`;

const StyledButton = styled.button`
    padding: 12px 30px;
    font-size: 18px;
    background-color: #4b3c30;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.4s;

    &:hover {
        background-color: #c8b99a;
    }
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Iscrizione completata: ${email}`);
    setEmail('');
  };

  return (
    <StyledContainer>
      <StyledTitle>Unisciti alla nostra comunità</StyledTitle>
      <StyledText>
        Collabora con noi in progetti entusiasmanti e cresci insieme a BlockSpira!
      </StyledText>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="email"
          placeholder="Inserisci la tua email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <StyledButton type="submit">Iscriviti</StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default Newsletter;