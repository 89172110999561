import styled from "styled-components";

const NewContainer = styled.section`
    background: linear-gradient(135deg, #f5f5dc, #e7d6b5);
    padding: 40px 20px;
    color: #333;
`;

const NewCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;

const NewCard = styled.div`
    flex: 1;
    min-width: 250px;
    max-width: 280px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    }
`;

const NewCardImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const NewCardContent = styled.div`
    padding: 15px;
    text-align: center;
`;

const NewCardTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
    color: #8b4513;
`;

const NewCardDescription = styled.p`
    font-size: 16px;
    line-height: 1.4;
    color: #7a7a7a;
`;

const PrivilegesInfinity = () => {
  return (
    <NewContainer>
      <NewCardsContainer>
        <NewCard>
          <NewCardImage src="/reacti3.jpg" alt="Idee Creative" />
          <NewCardContent>
            <NewCardTitle>Idee Creative</NewCardTitle>
            <NewCardDescription>
              Unisciti a noi per esplorare un flusso costante di idee innovative che possono trasformare i tuoi progetti.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti2.jpg" alt="Soluzioni Complete" />
          <NewCardContent>
            <NewCardTitle>Soluzioni Complete</NewCardTitle>
            <NewCardDescription>
              Scopri una gamma completa di servizi tecnologici per ottimizzare ogni aspetto del tuo progetto.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti1.jpg" alt="Esperti Tecnologici" />
          <NewCardContent>
            <NewCardTitle>Esperti Tecnologici</NewCardTitle>
            <NewCardDescription>
              Il nostro team di esperti offre strategie personalizzate per far progredire i tuoi progetti digitali.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
      </NewCardsContainer>
    </NewContainer>
  );
};
export default PrivilegesInfinity;