// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import InfinityComponent from './Components/InfinityComponent';
import CookieBanner from './Pages/CookieBanner';
import About from './Pages/About';
import Privacy from './Pages/Privacy';
import Contact from './Pages/Contact';
import TermsAndConditions from './Pages/TermsAndConditions';
import CookiePolicy from './Pages/CookiePolicy';
import Blog from './Components/Blog';
import Newsletter from './Components/Newsletter';
import Partners from './Components/Partners';
import Testimonials from './Components/Testimonials';
import PrivilegesComponent from './Components/PrivilegesComponent';
import BloqueProyectoStartup from './Components/BloqueProyectoStartup';
import Portfolio from './Components/Portfolio';
const App = () => {
  return (
    <Router>
      <div className="app-container min-h-screen bg-gray-100 text-gray-800">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
            <InfinityComponent />
                <BloqueProyectoStartup />
                <Newsletter />
                <Blog />
                <Partners />
                <Testimonials />
                <PrivilegesComponent />
                <Portfolio />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/policy" element={<Privacy />} />
        </Routes>
        <Footer />
        <CookieBanner />
      </div>
    </Router>
  );
};
export default App;
