import styled from "styled-components";

const StyledEspace = styled.section`
    background: linear-gradient(90deg, #f5e0d3, #e2c6a0);
    padding: 50px 30px;
    color: #4b3c2b;
`;

const EspaceWrapper = styled.div`
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const BoxVidéo = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* aspect ratio 16:9 */
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
`;

const CadreStylisé = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const TexteDescription = styled.p`
    font-size: 20px;
    line-height: 1.8;
    color: #4b3c2b;
    margin-top: 20px;
`;

const BoîteFormulaire = styled.div`
    background: rgba(255, 255, 255, 0.9);
    padding: 35px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const FormulaireInput = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const ChampInput = styled.input`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 18px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #e2b74d;
        outline: none;
    }
`;

const BoutonSoumettre = styled.button`
    padding: 16px;
    background-color: #e2b74d;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #d9a74d;
    }
`;

const BoîteAccord = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4b3c2b;
    margin-top: 15px;
    text-align: left;

    input[type="checkbox"] {
        margin-right: 10px;
    }

    a {
        color: #e2b74d;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const InfinityComponent = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        fetch("thanks/index.php", {
            method: "POST",
            body: formData,
        }).catch((error) => {
            console.error("Errore durante l'invio del modulo:", error);
        });

        alert("Richiesta inviata");
        e.target.reset();
    };

    return (
        <StyledEspace>
            <EspaceWrapper>
                <div>
                    <BoxVidéo>
                        <CadreStylisé
                            src="https://www.youtube.com/embed/3fQd_JA9gzs?si=udnd3Y6MF1ntCtIE"
                            title="Video di BlockSpira"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </BoxVidéo>
                    <TexteDescription>
                        BlockSpira è una comunità dinamica di sviluppatori che invita tutti a unirsi a noi per creare progetti insieme. La nostra missione è quella di esplorare nuove idee, condividere la conoscenza e costruire un futuro innovativo. Unisciti a noi in questo viaggio di scoperta e collaborazione, dove le possibilità sono infinite.
                    </TexteDescription>
                </div>
                <BoîteFormulaire>
                    <h3 style={{ marginBottom: "25px", color: "#4b3c2b" }}>Iscrizione</h3>
                    <FormulaireInput onSubmit={handleSubmit}>
                        <ChampInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Inserisci il tuo nome"
                            required
                        />
                        <ChampInput
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Inserisci il tuo cognome"
                            required
                        />
                        <ChampInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="esempio@mail.com"
                            required
                        />
                        <ChampInput
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="+39 600 000 000"
                            required
                        />
                        <BoîteAccord>
                            <input type="checkbox" id="terms" name="terms" required />
                            <label htmlFor="terms">
                                Accetto i{" "}
                                <a href="/terms" rel="noopener noreferrer">
                                    Termini e Condizioni
                                </a>{" "}
                                e la{" "}
                                <a href="/cookies" rel="noopener noreferrer">
                                    Politica sui Cookie
                                </a>
                                e la{" "}
                                <a href="/policy" rel="noopener noreferrer">
                                    Politica sulla Privacy
                                </a>
                            </label>
                        </BoîteAccord>
                        <BoutonSoumettre type="submit">Invia Richiesta</BoutonSoumettre>
                    </FormulaireInput>
                </BoîteFormulaire>
            </EspaceWrapper>
        </StyledEspace>
    );
};
export default InfinityComponent;