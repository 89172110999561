import styled from "styled-components";

const BlockSpiraWrapper = styled.section`
  width: 100%;
  padding: 50px 15px;
  background: linear-gradient(45deg, #e8d6c3, #f5ebd9);
  box-sizing: border-box;
`;

const BlockSpiraContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const BlockSpiraInfo = styled.div`
  flex: 1;
  min-width: 250px;
  text-align: center;
`;

const BlockSpiraImage = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
`;

const BlockSpiraText = styled.div`
    flex: 1;
    min-width: 250px;
`;

const BlockSpiraHeader = styled.h2`
    font-size: 32px;
    color: #d69c6d;
    margin-bottom: 15px;
    font-weight: bold;
`;

const BlockSpiraParagraph = styled.p`
    font-size: 16px;
    color: #4e3b30;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const About = () => {
  return (
    <BlockSpiraWrapper>
      <BlockSpiraContainer>
        <BlockSpiraText>
          <BlockSpiraHeader>Informazioni su BlockSpira!</BlockSpiraHeader>
          <BlockSpiraParagraph>
            In BlockSpira!, ci impegniamo a costruire un futuro insieme attraverso progetti collaborativi. Siamo un gruppo di sviluppatori appassionati che si riuniscono per creare soluzioni innovative e stimolanti.
          </BlockSpiraParagraph>
          <BlockSpiraParagraph>
            Unisciti a noi per esplorare nuove frontiere della tecnologia e del design. Insieme possiamo affrontare le sfide e realizzare idee uniche che hanno il potere di cambiare il mondo digitale. 
          </BlockSpiraParagraph>
        </BlockSpiraText>
      </BlockSpiraContainer>
    </BlockSpiraWrapper>
  );
};
export default About;