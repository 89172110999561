import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 40px 20px;
    background-color: #f5f0e1;
    box-sizing: border-box;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
    color: #3e3e3e;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
`;

const Card = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    max-width: 320px;
    flex: 1;
    min-width: 280px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }
`;

const Image = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const Content = styled.div`
    padding: 20px;
`;

const Title = styled.h3`
    font-size: 20px;
    margin-bottom: 10px;
    color: #3e3e3e;
    font-weight: 500;
`;

const Date = styled.p`
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
`;

const Excerpt = styled.p`
    font-size: 16px;
    color: #444;
    line-height: 1.5;
    margin-bottom: 15px;
`;

const Button = styled.a`
    display: inline-block;
    padding: 10px 20px;
    background-color: #3e3e3e;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #555;
    }
`;

const posts = [
  {
    image: "blog1.jpg",
    title: "Unisciti alla nostra Comunità di Sviluppatori",
    date: "15 Aprile 2025",
    excerpt:
      "BlockSpira invita tutti i programmatori a unirsi a noi per collaborare a progetti entusiasmanti e innovativi.",
    link: "#"
  },
  {
    image: "blog2.jpg",
    title: "Innovazioni Tecnologiche",
    date: "10 Aprile 2025",
    excerpt:
      "Scopri come le nostre ultime innovazioni stanno trasformando il modo in cui lavoriamo insieme nel mondo digitale.",
    link: "#"
  },
  {
    image: "blog3.jpg",
    title: "Collaborazioni per il Futuro",
    date: "5 Aprile 2025",
    excerpt:
      "Abbiamo avviato nuove collaborazioni che ci aiuteranno a crescere e a innovare nel settore tecnologico.",
    link: "#"
  }
];

const BlogComponent = () => {
  return (
    <Wrapper>
      <Heading>Ultime Novità</Heading>
      <Grid>
        {posts.map((post, index) => (
          <Card key={index}>
            <Image src={post.image} alt={post.title} />
            <Content>
              <Title>{post.title}</Title>
              <Date>{post.date}</Date>
              <Excerpt>{post.excerpt}</Excerpt>
              <Button href={post.link}>Scopri di più</Button>
            </Content>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default BlogComponent;