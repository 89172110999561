import React from "react";
import styled from "styled-components";

const StyledProjectContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 50px 15px;
    justify-content: center;
    background: linear-gradient(90deg, #f5f3e7, #e8d8c0);
    box-sizing: border-box;
`;

const StyledImageContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const StyledContentContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    background-color: #f7f3e6;
    border-radius: 16px;
    padding: 25px 25px 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const StyledProjectTitle = styled.h2`
    font-size: 24px;
    color: #8b5a2b;
    margin-bottom: 15px;
    font-weight: bold;
    position: relative;
    z-index: 2;
`;

const StyledProjectDescription = styled.p`
    font-size: 16px;
    color: #5d4037;
    line-height: 1.5;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
`;

const Decoration = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 6px;
    height: 100%;
    background-color: #8b5a2b;
    border-radius: 0 4px 4px 0;
    z-index: 1;
`;

const ProjectInfinityBlock = () => {
  return (
    <StyledProjectContainer>
      <StyledImageContainer>
        <StyledImage src="/infinity.jpg" alt="Progetto Infinità" />
      </StyledImageContainer>
      <StyledContentContainer>
        <Decoration />
        <StyledProjectTitle>BlockSpira: Unisciti alla Nostra Comunità</StyledProjectTitle>
        <StyledProjectDescription>
          Benvenuti in BlockSpira, dove sviluppatori appassionati collaborano per creare progetti innovativi.
          Unisciti a noi per condividere idee, esperienze e costruire insieme il futuro della tecnologia.
          Fai parte della comunità BlockSpira e contribuisci a nuovi traguardi!
        </StyledProjectDescription>
      </StyledContentContainer>
    </StyledProjectContainer>
  );
};

export default ProjectInfinityBlock;