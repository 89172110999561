import styled from "styled-components";

const Container = styled.section`
    width: 100%;
    padding: 40px 20px;
    background-color: #f5f5dc;
    box-sizing: border-box;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    color: #4b3d25;
    font-weight: bold;
`;

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

const LogoImage = styled.img`
    max-width: 150px;
    transition: filter 0.3s ease, transform 0.3s ease;
    filter: sepia(100%);

    &:hover {
        filter: sepia(0%);
        transform: scale(1.2);
    }
`;

const logosData = [
  { logo: "partn1.jpg", alt: "Logo 1" },
  { logo: "partn2.jpg", alt: "Logo 2" },
  { logo: "partn3.jpg", alt: "Logo 3" },
  { logo: "partn4.jpg", alt: "Logo 4" },
  { logo: "partn5.jpg", alt: "Logo 5" }
];

const Partners = () => {
  return (
    <Container>
      <Title>Unisciti alla nostra Comunità di Sviluppatori</Title>
      <GridContainer>
        {logosData.map((logo, index) => (
          <LogoImage key={index} src={logo.logo} alt={logo.alt} />
        ))}
      </GridContainer>
    </Container>
  );
};
export default Partners;