import styled from "styled-components";

const StyledLocationWrapper = styled.div`
    width: 100%;
    padding: 40px 20px;
    background-color: #ff6347;
    box-sizing: border-box;
`;

const StyledInnerContent = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const StyledHeading = styled.h2`
    font-size: 32px;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
`;

const StyledDescription = styled.p`
    font-size: 20px;
    color: #333;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 30px;
`;

const StyledMapContainer = styled.div`
    width: 100%;
    height: 500px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
`;

const BloqueUbicacion = () => {
    return (
        <StyledLocationWrapper>
            <StyledInnerContent>
                <StyledHeading>We are located in New York City, USA</StyledHeading>
                <StyledDescription>
                    Our team is based in the bustling city of New York, known for its vibrant energy and diverse culture.
                    Visit us in the heart of Manhattan and experience firsthand the dynamic work environment we thrive in.
                </StyledDescription>
                <StyledMapContainer>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.327993625094!2d-74.00684768403661!3d40.71277599387998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2588f046ee66b%3A0xa9922e5e2d02a9a3!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1615588055604!5m2!1en!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Map of New York City"
                    ></iframe>
                </StyledMapContainer>
            </StyledInnerContent>
        </StyledLocationWrapper>
    );
};
export default BloqueUbicacion;