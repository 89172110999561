import styled from "styled-components";

const NewWrapper = styled.div`
    max-width: 900px;
    margin: 60px auto 30px;
    padding: 30px 20px;
    background-color: #e8d5b1;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
`;

const NewHeaderText = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #8d6e63;
    font-weight: bold;
`;

const NewTextContent = styled.div`
    font-size: 20px;
    line-height: 1.8;
    color: #5d4037;

    p {
        margin-bottom: 15px;
    }

    strong {
        color: #8d6e63;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <NewWrapper>
        <NewHeaderText>Benvenuti in BlockSpira</NewHeaderText>
        <NewTextContent>
          <p>
            Siamo un comunità di sviluppatori appassionati che invitano tutti a unirsi a noi per creare progetti innovativi insieme.
          </p>
          <p>
            <strong>1. Unisciti a noi:</strong> Ogni sviluppatore è benvenuto a contribuire con le proprie idee e competenze per costruire insieme.
          </p>
          <p>
            <strong>2. Condivisione delle conoscenze:</strong> Crediamo nella crescita collettiva e nella condivisione delle conoscenze, quindi non esitare a partecipare alle discussioni.
          </p>
          <p>
            <strong>3. Rispetto reciproco:</strong> È fondamentale mantenere un ambiente rispettoso e collaborativo per tutti i membri della comunità.
          </p>
          <p>
            <strong>4. Aggiornamenti:</strong> Rimanete informati sulle ultime novità e sugli eventi della comunità attraverso il nostro sito.
          </p>
          <p>
            <strong>5. Contattaci:</strong> Se hai domande o desideri ulteriori informazioni, non esitare a contattarci.
          </p>
        </NewTextContent>
      </NewWrapper>
    </>
  );
};
export default TermsAndConditions;