import React from "react";
import styled from "styled-components";

const CustomFooter = styled.footer`
  background: linear-gradient(90deg, #f5e1c8, #d7c8b8);
  color: #3e3e3e;
  padding: 50px 20px 30px;
  box-sizing: border-box;
  border-top: 2px solid rgba(100, 100, 100, 0.7);
`;

const CustomFooterContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
`;

const CustomSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const CustomSectionTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 12px;
  font-weight: 500;
  color: #a57c3d;
`;

const CustomText = styled.p`
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 1.4;
`;

const CustomFooterLink = styled.a`
  color: #3e3e3e;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
  transition: color 0.3s;

  &:hover {
    color: #a57c3d;
  }
`;

const CustomFooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(100, 100, 100, 0.6);
  padding-top: 15px;
  font-size: 12px;
  margin-top: 20px;
`;

const Footer = () => {
  return (
      <CustomFooter>
        <CustomFooterContent>
          <CustomSection>
            <CustomSectionTitle>BlockSpira</CustomSectionTitle>
            <CustomText>Indirizzo: Via della Collaborazione, 12345 Roma, Italia</CustomText>
            <CustomText>Telefono: +39 06 1234 5678</CustomText>
            <CustomText>Email: contatto@blockspira.it</CustomText>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Collegamenti</CustomSectionTitle>
            <CustomFooterLink href="/" target="_blank" rel="noopener noreferrer">
              Home
            </CustomFooterLink>
            <CustomFooterLink href="/about" target="_blank" rel="noopener noreferrer">
              Chi siamo
            </CustomFooterLink>
            <CustomFooterLink href="/contact" target="_blank" rel="noopener noreferrer">
              Contatto
            </CustomFooterLink>
            <CustomFooterLink href="/policy" target="_blank" rel="noopener noreferrer">
              Politica sulla privacy
            </CustomFooterLink>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Seguici</CustomSectionTitle>
            <CustomFooterLink
                href="https://www.facebook.com/BlockSpira"
                target="_blank"
                rel="noopener noreferrer"
            >
              Facebook
            </CustomFooterLink>
            <CustomFooterLink
                href="https://twitter.com/BlockSpira"
                target="_blank"
                rel="noopener noreferrer"
            >
              Twitter
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.linkedin.com/company/BlockSpira"
                target="_blank"
                rel="noopener noreferrer"
            >
              LinkedIn
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.instagram.com/BlockSpira"
                target="_blank"
                rel="noopener noreferrer"
            >
              Instagram
            </CustomFooterLink>
          </CustomSection>
        </CustomFooterContent>
        <CustomFooterBottom>
          &copy; {new Date().getFullYear()} BlockSpira. Tutti i diritti riservati.
        </CustomFooterBottom>
      </CustomFooter>
  );
};

export default Footer;